$env: production;
@import "style/_preamble";

.bulkAssignment-box {
  input,
  textarea,
  [type="radio"] {
    border-color: #cbd5e0;
  }

  label {
    font-size: 14px;
    font-weight: 300;
    color: #7a8d99;
    font-weight: bold;
  }

  .textarea {
    box-sizing: border-box;
  }

  [type="number"] {
    max-width: 80px;
  }

  .landlookSheet-label {
    margin-bottom: 10px !important;
  }

  .bulkAssignment-buttonWrap {
    padding: 10px;
    text-align: center;

    button {
      margin: 0 3px;
    }
  }
}

$env: production;
@import "style/_preamble";

.offboardingLayout-box {
  .textarea {
    box-sizing: border-box;
  }

  .landlookSheet-label {
    margin-bottom: 10px !important;
  }

  .offboardingLayout-buttonWrap {
    padding: 10px;
    text-align: center;

    button {
      margin: 0 3px;
    }
  }
}

$env: production;
@import 'style/_preamble';


.AssignmentsAllocationsModal-box {
  .AssignmentsAllocationsModal-body {
    width: 800px;

    .AssignmentsAllocationsModal-container {
      padding: 10px 20px;
      background: $land-white;
      box-shadow: 0px 0px 0px 1px $land-grey-lightest inset;
      
      .whitePart-tableauActive {
        position: relative;
    
        &:after {
          content: '';
          display: block;
          position: absolute;
          right: 0px;
          top: 32%;
          bottom: 16%;
          width: 1px;
          background: $cloudy-sky;
        }
      }
    
      .whitePart-tableauHeader {
        display: flex;
        flex-direction: row;
        margin-bottom: 32px;
      }
    
      .whitePart-tableauTitle {
        display: inline-block;
    
        padding: 0px 30px;
        height: 32px;
        line-height: 32px;
        border-radius: 1px;
        
        font-size: 16px;
        letter-spacing: 2.5px;
        color: $land-white;
        font-weight: 300;
    
        background: $stormy-sky;
      }
    
      .whitePart-tableauMark {
        padding: 0px 30px;
        height: 32px;
        line-height: 32px;
    
        font-size: 16px;
        letter-spacing: 2.5px;
        color: $land-coral-light;
        font-weight: 300;
      }

      .whitePart-line {
        display: flex;
        flex-direction: row;
        line-height: 32px;
    
        margin-bottom: 12px;
      }
      
      .whitePart-lineLabel {
        width: 50px;
        flex-grow: 1;
    
        font-size: 14px;
        font-weight: 300;
        color: $stormy-sky;
      }
      
      .whitePart-lineContent {
        width: 50px;
        flex-grow: 2;
    
        font-size: 16px;
        font-weight: 500;
        color: $land-grey-darkest;
      }
    }
    
  }

  .AssignmentsAllocationsModal-footer {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    padding: 10px;
  }

  .AssignmentsAllocationsModal-footerButton {
    padding: 0px 30px;
    height: 32px;
    line-height: 32px;
    border-radius: 3px;

    font-size: 16px;
    letter-spacing: 2.5px;
    color: $land-white;
    font-weight: 300;

    cursor: pointer;

    @include button-blue-background;
  }

}

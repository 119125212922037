@import 'style/_preamble';


.Modal-overlay {
  position: absolute;
  left: 0px;
  right: 0px;
  top: 0px;
  bottom: 0px;

  background: rgba(0,0,0,0.75);
  z-index: $layer-modal;
  padding: 20px;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.Modal-box {
  position: relative;
  background: $land-white;

  overflow: scroll;
  box-shadow: 0px 0px 3px rgba(0,0,0,0.75);
  border-radius: 3px;
}

$env: production;
.scrolling-log {
  width: 100%;
  height: 300px;
  overflow: auto;
  font-family: monospace;
  line-height: 1.2;
  font-size: 1.1em;
  border: 1px solid #b5c1c9;
  padding: 0px 10px;
  border-radius: 3px;

  ul {
    li {
      margin-bottom: 5px;
      &.error {
        color: red;
      }
    }
  }
}

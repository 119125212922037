$env: production;
@import 'style/_preamble';


.Highlight-box {
  .Highlight-highlight {
    display: inline-block;
    background: linear-gradient(
      to bottom,
      rgba(#fff8c0, 0) 0%,
      rgba(#fff8c0, 0) 14%,
      rgba(#fff8c0, 1) 15%,
      rgba(#fff8c0, 1) 81%,
      rgba(#fff080, 1) 85%,
      rgba(#fff080, 0) 86%,
      rgba(#fff080, 0) 100%
    );
  }
}

/* Color constants */
$land-white: #ffffff;
$land-snow: #fafafa;
$land-grey-lightest: #F2F2F2;
$land-grey-light: #CACACA;
$land-grey: #969696;;
$land-grey-dark: #4D4D4D;
$land-grey-darkest: #333333;
$land-almost-black: #121212;
$land-black: #000000;

$pale-sky: #d8dee3;
$cloudy-sky: #b5c1c9;
$stormy-sky: #7a8d99;

$land-coral-light: #ff7f50;
$land-coral: #f35e27;
$land-red: #E73B37;
$land-spark: #ff6666;
$land-blue: #2196f3;
$land-blue-dark: #3C72C7;
$land-green: #3cc300;

$mobile-max-width: 640px;

$layer-base: 1;
$layer-modal: 10000;

@mixin roboto {
  font-family: 'Roboto', sans-serif;
}

@mixin button-blue-background {
  background: linear-gradient(
    to bottom,
    $land-blue,
    $land-blue,
    $land-blue,
    $land-blue-dark,
    $land-blue-dark,
    $land-blue-dark
  );
  background-size: 300% 300%;
  background-position-y: 0%;
  transition: background-position-y 0.2s;

  border: none;
  outline: none;

  &:hover {
    background-position-y: 100%;
    border: none;
    outline: none;
  }

  &:active, &:focus {
    border: none;
    outline: none;
  }
}

@mixin white-blue-background {
  background: linear-gradient(
    to bottom,
    $land-white,
    $land-white,
    $land-white,
    $land-blue-dark,
    $land-blue-dark,
    $land-blue-dark
  );
  background-size: 300% 300%;
  background-position-y: 0%;
  transition: background-position-y 0.2s;

  border: none;
  outline: none;

  &:hover {
    background-position-y: 100%;
    border: none;
    outline: none;
  }

  &:active, &:focus {
    border: none;
    outline: none;
  }
}

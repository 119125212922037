$env: production;
@import 'style/_preamble';

.DateRangePicker-box {
  position: relative;

  .DateRangePicker-input {
    min-width: 200px;
    text-align: center;

    &.DateRangePicker-populated {
      text-align: left;
      padding-left: 10px;
    }
  }

  .DateRangePicker-rangeSelector {
    position: absolute;
    z-index: 10;
  }

  .DateRangePicker-clearIcon {
    position: absolute;
    right: 4px;
    top: 50%;
    transform: translateY(-50%);
    border-radius: 50%;
    padding: 6px;
    cursor: pointer;

    &:hover {
      background-color: lightgray;
    }
  }
}

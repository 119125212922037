@import 'style/_preamble';

.landlookBlueButton {
  display: inline-block;
  padding: 0px 30px;
  height: 32px;
  line-height: 32px;
  border-radius: 3px;
  
  font-size: 16px;
  letter-spacing: 2.5px;
  color: $land-white;
  font-weight: 300;

  cursor: pointer;

  @include button-blue-background;

  &:disabled {
    background: #333333;
    cursor: not-allowed;
  }
}

.landlookIconButton {
  height: 32px;
  width: 32px;
  padding: 8px;

  cursor: pointer;

  svg {
    height: 16px;
    width: 16px;

    fill: $land-blue;
  }

  &:hover svg {
    fill: $land-coral;
  }
}

$env: production;
@import 'style/_preamble';


.whitePart-box {
  padding: 10px 20px;
  margin: 0px -20px 50px;
  background: $land-white;
  box-shadow: 0px 0px 0px 1px $land-grey-lightest inset;

  .whitePart-menu {
    text-align: right;
  }

  .whitePart-menuButton {
    display: inline-block;
    padding: 0px 30px;
    height: 32px;
    line-height: 32px;
    border-radius: 3px;
    
    font-size: 16px;
    letter-spacing: 2.5px;
    color: $land-white;
    font-weight: 300;

    margin-left: 20px;

    cursor: pointer;

    @include button-blue-background;
  }

  .whitePart-menuWhiteButton {
    display: inline-block;
    padding: 0px 30px;
    height: 32px;
    line-height: 32px;
    border-radius: 3px;
    
    font-size: 16px;
    letter-spacing: 2.5px;
    color: $stormy-sky;
    font-weight: 300;

    margin-left: 20px;

    cursor: pointer;

    @include white-blue-background;

    &:hover {
      color: $land-white;
    }
  }


  .whitePart-tableauActive {
    position: relative;

    &:after {
      content: '';
      display: block;
      position: absolute;
      right: 0px;
      top: 32%;
      bottom: 16%;
      width: 1px;
      background: $cloudy-sky;
    }
  }

  .whitePart-tableauHeader {
    display: flex;
    flex-direction: row;
    margin-bottom: 32px;
  }

  .whitePart-tableauTitle {
    display: inline-block;

    padding: 0px 30px;
    height: 32px;
    line-height: 32px;
    border-radius: 1px;
    
    font-size: 16px;
    letter-spacing: 2.5px;
    color: $land-white;
    font-weight: 300;

    background: $stormy-sky;
  }

  .whitePart-tableauTitleButton {
    display: inline-block;
    padding: 0px 30px;
    height: 32px;
    line-height: 32px;
    border-radius: 3px;
    
    font-size: 16px;
    letter-spacing: 2.5px;
    color: $land-white;
    font-weight: 300;

    cursor: pointer;

    @include button-blue-background;
  }

  .whitePart-tableauMark {
    padding: 0px 30px;
    height: 32px;
    line-height: 32px;

    font-size: 16px;
    letter-spacing: 2.5px;
    color: $land-coral-light;
    font-weight: 300;
  }

  .whitePart-tableauRemoveButton {
    display: inline-block;
    padding: 0px 30px;
    height: 32px;
    line-height: 32px;
    border-radius: 3px;
    
    font-size: 16px;
    letter-spacing: 2.5px;
    color: $stormy-sky;
    font-weight: 300;

    margin-left: 20px;

    cursor: pointer;

    @include white-blue-background;

    &:hover {
      color: $land-white;
    }
  }

  .whitePart-line {
    display: flex;
    flex-direction: row;
    line-height: 32px;

    margin-bottom: 12px;
  }
  
  .whitePart-lineLabel {
    width: 50px;
    flex-grow: 1;

    font-size: 14px;
    font-weight: 300;
    color: $stormy-sky;
  }
  
  .whitePart-lineContent {
    width: 50px;
    flex-grow: 2;

    font-size: 16px;
    font-weight: 500;
    color: $land-grey-darkest;
  }

  .whitePart-input {
    border: 1px solid $cloudy-sky;
    padding: 0px 10px;
    border-radius: 3px;

    font-size: 16px;
    font-weight: 400;
    line-height: 32px;

    &:active, &:focus {
      border: 1px solid $land-blue;
      outline: none;
    }
  }

  .whitePart-percentInput {
    border: 1px solid $cloudy-sky;
    padding: 0px 10px;
    border-radius: 3px;

    font-size: 16px;
    font-weight: 400;
    line-height: 32px;

    width: 60px;

    &:active, &:focus {
      border: 1px solid $land-blue;
      outline: none;
    }
  }

  .whitePart-selectInput {
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;

    background: $land-white;

    border: 1px solid $cloudy-sky;
    padding: 0px 10px;
    border-radius: 3px;

    font-size: 16px;
    font-weight: 400;
    line-height: 32px;

    vertical-align: bottom;
    margin-right: 12px;

    &:active, &:focus {
      border: 1px solid $land-blue;
      outline: none;
    }
  }

  .whitePart-errors {
    font-family: Roboto;
    font-size: 14px;
    font-weight: 500;
    color: $land-spark;
  }

  .whitePart-error {
    margin-bottom: 20px;
  }

}

@import 'style/_preamble';

.landlookGrid-box {

  .landlookGrid-row {
    display: flex;
    flex-direction: row;
    margin-bottom: 40px;
  }
  
  .landlookGrid-hr {
    height: 1px;
    background: $cloudy-sky;
    margin-bottom: 40px;
  }
  
  @for $i from 1 through 12 {
    .landlookGrid-column#{$i} {
      padding: 0px 10px;
      width: 0px;
      flex-grow: $i;
      flex-shrink: $i;
    }
  }
  
  .landlookGrid-columnRightBordered {
    border-right: 1px solid $cloudy-sky;
  }

}

.landlookPages-box {

  display: flex;
  flex-direction: row;

  background: $land-white;
  border-radius: 3px;
  border: 1px solid $land-grey-lightest;

  .landlookPages-page {
    display: block;
    width: 32px;
    height: 24px;
    line-height: 24px;
    text-align: center;

    color: $land-grey-light;
    cursor: pointer;

    &:hover {
      background: $land-grey-lightest;
    }

    &.active {
      color: $land-blue;
    }
  }
}

@import 'style/_preamble';

.landlookList-box {
  flex-grow: 1;

  display: flex;
  flex-direction: column;

  .landlookList-header {
    height: 48px;
    flex-grow: 0;
    flex-shrink: 0;
  }

  .landlookList-content {
    height: 48px;
    flex-grow: 1;
    flex-shrink: 1;
  }
  
  .landlookList-emptyContent {
    height: 48px;
    flex-grow: 1;
    flex-shrink: 1;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .landlookList-emptyImage {
    width: 160px;
  }

  .landlookList-emptyNote {
    margin-top: 32px;
    font-weight: 500;
    font-size: 18px;
    color: $land-grey-darkest;
  }

  @for $i from 1 through 12 {
    .landlookListColumn-#{$i} {
      width: 0px;
      flex-grow: $i;
      flex-shrink: $i;
    }
  }

}

.landlookListHeader-box {
  display: flex;
  flex-direction: row;

  .landlookListHeader-column {
    height: 48px;
    line-height: 48px;
    font-size: 12px;
    color: $land-grey-darkest;
    font-weight: 500;

    text-align: center;
    padding: 0px 10px;
  }
}



.landlookListItem-box {

  .landlookListItem-indicator {
    display: inline-block;
    vertical-align: top;
    width: 6px;
    height: 32px;
    margin-right: 10px;
    padding-top: 13px;
  }

  .landlookListItem-indicatorInner {
    width: 6px;
    height: 6px;
    border-radius: 6px;
    background: $land-coral-light;
  }

  .landlookListItem-content {
    background: $land-white;
    box-shadow: 0px 0px 0px 1px $land-grey-lightest inset;
    height: 48px;
    line-height: 48px;

    display: flex;
    flex-direction: row;
  }

  .landlookListItem-column {
    // text-align: left;
    padding: 8px 10px;
    height: 48px;
    line-height: 32px;
    font-weight: 300;
    
    white-space: nowrap;
    overflow-x: auto;
    overflow-y: hidden;

    font-size: 13px;

    &.landlookListItem-form {
      display: flex;
      align-items: center;
      justify-content: center;
    }

    &.landlookListItem-title {
      font-weight: 400;
    }

    &.landlookListItem-link {
      color: $land-blue;
      font-weight: 400;

      &:hover {
        color: mix(white, $land-blue, 40%);
      }
    }

    @for $i from 1 through 2 {
      &.landlookListColumn-#{$i} {
        text-align: center;
      }
    }

    &.landlookListItem-menu {

      background: $land-white;
      border-radius: 3px;

      font-weight: 300;
      font-size: 14px;

      z-index: 1;
    }

    .landlookListItem-button {
      padding: 0px 8px;

      text-align: center;
      font-weight: 500;

      height: 28px;
      line-height: 28px;

      border: 1px solid $land-grey-light;

      &:active {
        background: $land-blue;
        color: $land-white;
      }
    }
  }

}


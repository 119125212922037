$env: production;
@import "style/_preamble";

.userCheckerLayout-box {
  .userCheckerLayout-textWrap {
    position: relative;
    width: 100%;

    textarea {
      width: 100%;
      line-height: 1.5;
      min-height: 300px;
    }

    .userCheckerLayout-loader {
      position: absolute;
      left: 0;
      right: 0;
    }
  }

  .landlookSheet-label {
    margin-bottom: 10px !important;
  }

  .userCheckerLayout-buttonWrap {
    padding: 10px;
    text-align: center;

    button {
      margin: 0 3px;
    }
  }
}

@import 'style/_preamble';

.landlookHorizontalForm-box {
  display: flex;
  flex-direction: row;
  height: 48px;
  align-items: center;

  .landlookHorizontalForm-space {
    flex-grow: 1;
  }

  .landlookHorizontalForm-part {
    display: flex;
    flex-direction: row;
  }
}


.landlookFormCheckbox-box {
  display: flex;
  flex-direction: row;
  height: 32px;
  align-items: center;
  justify-content: center;
  margin: 0px 20px;

  .landlookFormCheckbox-checkbox {
    display: block;
    width: 20px;
    height: 20px;
    border: 1px solid $land-blue;

    &.active {
      background: $land-blue;
      box-shadow: 0px 0px 0px 1px $land-white inset;
    }
  }

  .landlookFormCheckbox-label {
    margin-left: 20px;
    font-size: 13px;
    font-weight: 500;
    color: $land-grey-darkest;
  }
}


.landlookFormField-box {
  display: flex;
  flex-direction: row;
  height: 32px;
  line-height: 32px;
  align-items: center;
  margin: 0px 20px;
  min-width:60px;

  

  .landlookFormField-field {
    height: 32px;
    line-height: 30px;

    font-size: 15px;
    font-weight: 300;
    padding: 0px 5px;
    color: $land-grey-dark;

    width: 100%;

    border: 1px solid $pale-sky;
    outline: none;
    box-shadow: none;

    &:focus, &:active {
      border: 1px solid $land-blue;
      outline: none;
      box-shadow: none;
    }
  }

  .landlookFormField-postLabel {
    font-size: 13px;
    font-weight: 500;
    background: $land-blue;
    color: $land-white;
    padding: 0px 20px;

    border-top-right-radius: 3px;
    border-bottom-right-radius: 3px;
  }
}

@import "style/_preamble";

.landlookSheet-box {
  margin: 40px 0px;

  .landlookSheet-title {
    font-size: 30px;
    font-weight: 500;
    font-color: $land-grey-darkest;
  }

  .landlookSheet-field {
    display: flex;
    flex-direction: column;

    &:not(:first-child) {
      margin-top: 20px;
    }
  }

  .landlookSheet-indicator {
    display: inline-block;
    vertical-align: middle;
    width: 6px;
    height: 32px;
    margin-right: 10px;
    padding-top: 12px;
  }

  .landlookSheet-indicatorInner {
    width: 6px;
    height: 6px;
    border-radius: 6px;
    background: $land-coral-light;
  }

  .landlookSheet-label {
    font-size: 14px;
    font-weight: 300;
    color: $stormy-sky;

    margin-bottom: 20px;
  }

  .landlookSheet-value {
    font-size: 16px;
    font-weight: 500;
    color: $land-grey-darkest;
  }

  .landlookSheet-error {
    font-size: 13px;
    font-weight: 400;
    color: $land-red;
  }

  .landlookSheet-noValue {
    font-size: 16px;
    font-weight: 500;
    color: $cloudy-sky;
  }

  .landlookSheet-input {
    border: 1px solid $cloudy-sky;
    padding: 0px 10px;
    border-radius: 3px;

    font-size: 16px;
    font-weight: 400;
    line-height: 32px;

    &:active,
    &:focus {
      border: 1px solid $land-blue;
      outline: none;
    }
  }

  .landlookSheet-boolInput {
    display: inline-block;
    margin-right: 48px;

    line-height: 20px;

    cursor: pointer;
  }

  .landlookSheet-boolInputMarker {
    display: inline-block;
    vertical-align: text-bottom;

    width: 20px;
    height: 20px;
    border-radius: 20px;

    border: 2px solid $land-blue;
    background: $land-white;

    margin-right: 16px;
  }

  .landlookSheet-boolInput.active .landlookSheet-boolInputMarker {
    background: $land-blue;
    box-shadow: 0px 0px 0px 2px $land-white inset;
  }
}

.landlookLayout-universe {
  position: absolute;
  left: 0px;
  right: 0px;
  top: 0px;
  bottom: 0px;

  display: flex;
  flex-direction: column;
}

.landlookLayout-region {
  flex-grow: 1;
  flex-shrink: 1;

  position: relative;
  display: flex;
  flex-direction: column;
}

.landlookLayout-row {
  display: flex;
  flex-direction: row;
}

.landlookLayout-maxWrap {
  margin: 0px auto;
  max-width: 1200px;

  flex-grow: 1;
}

.landlookLayout-scrollPage {
  flex-grow: 1;
  overflow-y: scroll;
}

.landlookLayout-fixedPage {
  flex-grow: 1;
  flex-shrink: 1;

  display: flex;
  flex-direction: row;
  justify-content: center;
  padding-top: 10px;
}

.landlookLayout-fixedPageWrap {
  width: 1800px;
  flex-grow: 0;
  flex-shrink: 1;

  position: relative;
  display: flex;
  flex-direction: column;
}

.landlookLayout-tmp {
  flex-grow: 1;
  flex-shrink: 1;
  padding: 20px;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.landlookLayout-tmpTitle {
  padding: 20px;
  font-size: 24px;
  font-weight: 700;
  color: $land-almost-black;
}

.landlookLayout-tmpImage {
  width: 160px;
}





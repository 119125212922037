$env: production;
@import 'style/_preamble';

html, body, #root {
  height: 100%;
}

body {
  font-family: sans-serif;
  background: $land-snow;
  color: $land-grey-dark;
  @include roboto;
}

.ReactVirtualized__List {
  outline: none;
  &:active, &:focus {
    outline: none;
  }
}

.tinyLoad {
  width: 24px;
  height: 24px;
  img {
    width: 24px;
    height: 24px;
  }
}

@import 'style/landlook/index';
@import 'style/noomscape/Modal';
@import 'style/noomscape/Toast';

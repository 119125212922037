$env: production;
@import "style/_preamble";

.groupRemoverLayout-box {
  .groupRemoverLayout-textWrap {
    position: relative;
    width: 100%;

    textarea {
      width: 100%;
      line-height: 1.5;
      min-height: 300px;
    }

    .groupRemoverLayout-loader {
      position: absolute;
      left: 0;
      right: 0;
    }
  }

  .landlookSheet-label {
    margin-bottom: 10px !important;
  }

  .groupRemoverLayout-buttonWrap {
    padding: 10px;
    text-align: center;

    button {
      margin: 0 3px;
    }
  }
}

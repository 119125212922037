$env: production;
@import 'style/_preamble';


.SortingHeader-box {
  cursor: pointer;

  .SortingHeader-icon {
    display: inline-block;
    width: 12px;
    height: 12px;
    margin-left: 3px;

    fill: $land-grey-light;
  }

  &.active .SortingHeader-icon {
    fill: $land-black;
  }

  &:hover .SortingHeader-icon {
    fill: $land-coral;
  }
}

$env: production;
@import 'style/_preamble';


.weekdays-box {
  display: flex;
  flex-direction: row;

  .weekdays-day {
    width: 36px;
    height: 36px;
    line-height: 36px;
    text-align: center;

    border-radius: 3px;

    background: $land-grey-lightest;
    color: $land-grey-darkest;

    margin-right: 20px;

    &.weekdays-weekend {
      background: $land-white;
      color: $pale-sky;
      border: 1px dashed $pale-sky;
    }

    &.active {
      background: $stormy-sky;
      color: $land-snow;
      border: none;
    }
  }

  &.weekdays-edit .weekdays-day {
    cursor: pointer;

    &:hover {
      border: 2px solid $land-blue;
      background: $land-white;
      color: $land-blue;
    }

    &.active:hover {
      background: $land-blue;
      color: $land-snow;
      border: none;
    }
  }
}


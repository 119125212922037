$env: production;
@import 'style/_preamble';

.CDUFilterBox-box {


  .CDUFilterBoxDropdown-box {
    position: relative;
  }

  .CDUFilterBoxDropdown-toggle {
    background: $land-white;
    border: 1px solid $land-grey-light;
    border-radius: 3px;

    height: 32px;
    line-height: 32px;
    font-weight: 300;
    font-size: 14px;
    padding: 0px 16px;
    margin-left: 20px;
  }

  .CDUFilterBoxDropdown-label {
    font-weight: 500;
  }

  .CDUFilterBoxDropdown-menu {
    position: absolute;
    top: 30px;
    right: 0px;
    display: none;

    background: $land-white;
    border: 1px solid $land-grey-light;
    border-radius: 3px;

    font-weight: 300;
    font-size: 14px;
    padding: 16px;

    text-align: center;

    z-index: 1;
  }

  .CDUFilterBoxDropdown-buttons {
    cursor: pointer;
    display: flex;
    flex-direction: row;
  }

  .CDUFilterBoxDropdown-button {
    padding: 0px 16px;

    text-align: center;
    font-weight: 500;

    height: 28px;
    line-height: 28px;

    border: 1px solid $land-blue;
    &:not(:last-child) {
      border-right: none;
    }

    &.active {
      background: $land-blue;
      color: $land-white;
    }
  }

  .CDUFilterBoxDropdown-box:hover .CDUFilterBoxDropdown-menu {
    display: block;
  }
}



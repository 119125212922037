@import "style/_preamble";

.landlookTopBar-box {
  width: 100%;
  height: 64px;
  flex-grow: 0;
  flex-shrink: 0;

  line-height: 64px;
  background: $land-white;
  box-shadow: 0px -1px 0px $land-grey-lightest inset;

  display: flex;
  flex-direction: row;

  .landlookTopBar-part {
    flex-grow: 0;
    flex-shrink: 0;

    display: flex;
    flex-direction: row;
  }

  .landlookTopBar-space {
    flex-grow: 1;
    flex-shrink: 1;

    display: flex;
    flex-direction: row;
  }

  .landlookTopBar-logo {
    padding: 0px 20px;
    color: $land-coral;
    font-weight: 500;
    font-size: 20px;
  }

  .landlookTopBar-item {
    color: $land-grey;
    font-weight: 300;
    padding: 0px 20px;

    &.active {
      color: $land-almost-black;
      font-weight: 500;
      box-shadow: 0px -3px 0px $land-coral inset;
    }

    &:hover {
      color: $land-almost-black;
    }
  }

  .landlookTopBar-dropdown {
    position: relative;
    z-index: 100;
  }

  .landlookTopBar-dropdownButton {
    padding: 0px 20px;
    color: $land-grey-darkest;
    font-size: 14px;
    font-weight: 500;

    cursor: default;

    &.landlookTopBar-item {
      font-weight: 300;
    }
  }

  .landlookTopBar-dropdownList {
    display: none;
    position: absolute;
    right: 0px;
    top: 64px;

    background: $land-white;
    // box-shadow: 0px -1px 0px $land-grey-lightest inset;
    box-shadow: -3px -3px 0px $land-coral inset;

    z-index: 1;
  }

  .landlookTopBar-dropdownListItem {
    display: block;
    color: $land-grey;
    font-weight: 300;
    font-size: 13px;
    padding: 0px 23px 0px 40px;

    text-align: right;

    height: 48px;
    line-height: 48px;

    cursor: pointer;
    white-space: pre;

    &:hover {
      color: $land-almost-black;
    }

    &.active {
      font-weight: bold;
    }
  }

  .landlookTopBar-dropdown:hover .landlookTopBar-dropdownButton {
    box-shadow: 0px -3px 0px $land-coral inset;
  }

  .landlookTopBar-dropdown:hover .landlookTopBar-dropdownList {
    display: block;
  }
}

$env: production;
@import 'style/_preamble';

.body-mainLayout {
  background: $land-snow;
}

.mainLayout-box {
  b {
    font-weight: bold;
  }
  
  i {
    font-style: italic;
  }
}

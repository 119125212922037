$env: production;
@import 'style/_preamble';


.AssignmentsSettingsModal-box {
  .AssignmentsSettingsModal-body {
    padding: 30px 20px 50px;
  }

  .AssignmentsSettingsModal-line {
    display: flex;
    flex-direction: row;
  }

  .AssignmentsSettingsModal-note {
    flex-grow: 1;
    flex-shrink: 1;
    line-height: 32px;
    padding-right: 80px;
  }

  .AssignmentsSettingsModal-toggleButton {
    flex-grow: 0;
    flex-shrink: 0;

    padding: 0px 30px;
    height: 32px;
    line-height: 32px;
    border-radius: 3px;
      
    font-size: 16px;
    letter-spacing: 2.5px;
    color: $land-white;
    font-weight: 300;

    cursor: pointer;

    @include button-blue-background;
  }

  .AssignmentsSettingsModal-footer {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    padding: 10px;
  }

  .AssignmentsSettingsModal-footerButton {
    padding: 0px 30px;
    height: 32px;
    line-height: 32px;
    border-radius: 3px;

    font-size: 16px;
    letter-spacing: 2.5px;
    color: $land-white;
    font-weight: 300;

    cursor: pointer;

    @include button-blue-background;
  }

}

.Toast-overlay {
  position: fixed;
  left: 0px;
  right: 0px;
  top: 0px;
  bottom: 0px;

  pointer-events: none;


  .Toast-area {
    position: absolute;
    left: 0px;
    bottom: 0px;
  }

  .Toast-icon {
    width: 24px;
    height: 24px;
    flex-grow: 0;

    svg {
      width: 24px;
      height: 24px;
      fill: $land-blue;
    }
  }

  .Toast-message {
    flex-grow: 1;
    margin-left: 20px;
  }

  .Toast-box {
    margin: 10px 20px;
    background: #fcfeff;
    padding: 15px 30px 15px 10px;
    border-radius: 1px;
    color: $stormy-sky;
    box-shadow: 6px 0px 0px -3px $land-blue inset, 0px 1px 3px rgba(0,0,0,0.5);
    font-size: 14px;
    font-weight: 300;
    min-width: 240px;
    text-align: center;

    pointer-events: all;
    cursor: not-allowed;

    display: flex;
    flex-direction: row;
    align-items: center;

    &.Toast-box-success {
      box-shadow: 6px 0px 0px -3px $land-green inset, 0px 1px 3px rgba(0,0,0,0.5);
      color: $land-green;

      .Toast-icon svg {
        fill: $land-green;
      }
    }

    &.Toast-box-error {
      box-shadow: 6px 0px 0px -3px $land-red inset, 0px 1px 3px rgba(0,0,0,0.5);
      color: $land-red;

      .Toast-icon svg {
        fill: $land-red;
      }
    }
  }


}